import Vue from 'vue';
// 在这里引入你所需的组件
import {
  Button,
  Toast,
  Form,
  Field,
  Cell,
  CellGroup,
  Popup,
  DatetimePicker,
  Icon,
  List,
  RadioGroup,
  Radio,
  Picker,
  Tab,
  Tabs,
  Checkbox,
  CheckboxGroup,
} from 'vant';

// 按需引入UI组件
Vue.use(Button);
Vue.use(Toast);
Vue.use(Form);
Vue.use(Field);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.use(Icon);
Vue.use(List);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Picker);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
