import store from '@/store';
export function store2sessionStorage() {
  sessionStorage.setItem('store', JSON.stringify(store.state));
}

export function sessionStorage2store() {
  if (sessionStorage.getItem('store')) {
    store.replaceState(Object.assign({}, store.state, JSON.parse(sessionStorage.getItem('store'))));
  }
}
