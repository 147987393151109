import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import {Toast} from 'vant';

Vue.use(VueRouter);

const routes = [
  {
    path: '/gz',
    name: 'Index',
    component: () => import('@/views/Index.vue'),
  },
  {
    path: '/tj',
    name: 'tj',
    component: () => import('@/views/tj.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;

router.beforeEach((to, from, next) => {
  next();
});
