import Vue from 'vue';
import Moment from 'moment';
Vue.prototype.$moment = Moment;

// 定义全局时间戳过滤器
Vue.filter('formatDate', function (value) {
  return Moment(value).format('YYYY年MM月');
});

Vue.filter('formatSecond', function (value) {
  return Moment(value).format('YYYY年MM月DD日 hh:mm:ss');
});
