<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import {resize} from '@/utils/resize.js';
import {store2sessionStorage, sessionStorage2store} from '@/utils/syncStore.js';
export default {
  name: 'app',
  created() {
    sessionStorage2store();
    resize(document, window);
    // 在页面刷新时将store保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      store2sessionStorage();
    });
  },
  methods: {},
};
</script>

<style lang="postcss"></style>
