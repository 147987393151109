import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/plugins/vant';
import '@/assets/css/tailwind.css';
import '@/plugins/ramda';
import '@/plugins/moment';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
